/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	production: false,
	environmentName: 'qa',

	partUrl: 'https://api-qat.mss.ford.com/panda/',
	orderUrl: 'https://api-qat.mss.ford.com/panda-order/',
	// orderUrl:'https://panda-order-qa.apps.pd01i.edc1.cf.ford.com/panda-order/',
	// partUrl:'https://panda-parts-qa.apps.pd01i.edc1.cf.ford.com/panda/',
// 	orderUrl:'https://panda-order-qa.apps.pd01i.edc1.cf.ford.com/panda-order/',
// 	partUrl:'https://panda-parts-qa.apps.pd01i.edc1.cf.ford.com/panda/',
	i03TempUrl: 'https://api.qa01e.gcp.ford.com/panda-order/api/',
	//webUrl: 'https://wwwqa.panda.ford.com/api/apic/roles/',
	//iplUrl: 'https://panda-orderloadmoderniplservice-qa.apps.pp01i.edc1.cf.ford.com/iplsearch/api/',
	iplUrl: 'https://api.qa01i.gcp.ford.com/iplsearch/api/',
	hostUrl: 'https://wwwqa.panda.ford.com/',
	webClientId: 'urn:pandaexternalapp:clientid:web_dealersupplierapp:qa',
	webResourceId: 'urn:pandaexternalapp:resource:web_dealersupplierapp:qa',
	accessTokenUrl: 'https://corpqa.sts.ford.com',
	//baseUrl: 'https://wwwqa.panda.ford.com/panda-supplier/api/',

	 baseUrl: 'https://api-qat.mss.ford.com/panda-supplier/api/',

	device:'web'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
